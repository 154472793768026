// eslint-disable-next-line simple-import-sort/imports
import {
  Blockquote,
  Code,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Hr,
  Li,
  Link,
  Ol,
  P,
  Table,
  Ul,
} from '@dnb/eufemia';
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import type { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './index.styles.css';
import slugify from 'slugify';
import MermaidDiagram from '@portals/shared-frontend/components/MermaidDiagram';

interface MarkdownProps {
  children: string;
}
interface Props {
  children: ReactNode | string;
  inline?: boolean;
  className?: string;
}

export default function Markdown({ children }: MarkdownProps): JSX.Element {
  const CodeSection = ({ children, ...props }: Props) => {
    if (
      Object.keys(props).includes('className') &&
      props['className'] === 'language-mermaid'
    ) {
      return <MermaidDiagram content={children} />;
    }
    if (!props?.inline) {
      const lang = props['className']
        ? (props['className'] as string).replace('language-', '')
        : 'txt';
      let prismCode;

      try {
        prismCode = Prism.highlight(
          children?.toString() || '',
          Prism.languages[lang],
          lang,
        );
      } catch {
        prismCode = Prism.highlight(
          children?.toString() || '',
          Prism.languages['txt'],
          'txt',
        );
      }
      return (
        <div className="code">
          <pre className={`dnb-pre prism-code language-${lang}`}>
            <div dangerouslySetInnerHTML={{ __html: prismCode }} />
          </pre>
        </div>
      );
    }

    return <Code>{children}</Code>;
  };

  return (
    <ReactMarkdown
      components={{
        br: () => <br />,
        h1: ({ ...props }) => (
          <H1
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H1>
        ),
        h2: ({ ...props }) => (
          <H2
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H2>
        ),
        h3: ({ ...props }) => (
          <H3
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H3>
        ),
        h4: ({ ...props }) => (
          <H4
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H4>
        ),
        h5: ({ ...props }) => (
          <H5
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H5>
        ),
        h6: ({ ...props }) => (
          <H6
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H6>
        ),
        hr: () => <Hr />,
        ul: ({ ...props }) => (
          <Ul bottom="medium" top="medium">
            {props.children}
          </Ul>
        ),
        ol: ({ ...props }) => (
          <Ol bottom="medium" top="medium">
            {props.children}
          </Ol>
        ),
        li: ({ ...props }) => (
          <Li bottom="medium" top="medium">
            {props.children}
          </Li>
        ),
        blockquote: ({ ...props }) => (
          <Blockquote bottom="medium" top="medium">
            {props.children}
          </Blockquote>
        ),
        table: ({ ...props }) => (
          <Table bottom="medium" top="medium" {...props}>
            {props.children}
          </Table>
        ),
        a: ({ ...props }) => <Link {...props}>{props.children}</Link>,
        code: ({ ...props }) => (
          <CodeSection {...props}>{props.children}</CodeSection>
        ),
        p: ({ ...props }) => <P {...props}>{props.children}</P>,
      }}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
}
